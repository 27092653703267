import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "../modules/common/navbar/Loading";
import HomeRedirectStore from "../modules/home/presentation/components/HomeRedirectStore";

// Lazy load modules
const HomeRoutes = lazy(() => import("../modules/home/connector/ModuleRoutes"));
const FinanceRoutes = lazy(
  () => import("../modules/finance/connector/ModuleRoutes")
);
const LoansRoutes = lazy(
  () => import("../modules/loans/connector/ModuleRoutes")
);
const CardsRoutes = lazy(
  () => import("../modules/cards/connector/ModuleRoutes")
);
const ContactRoutes = lazy(
  () => import("../modules/contact/connector/ModuleRoutes")
);
const AboutUsRoutes = lazy(
  () => import("../modules/aboutus/connector/ModuleRoutes")
);
const InsurancesRoutes = lazy(
  () => import("../modules/insurance/connector/ModuleRoutes")
);
const InsuranceApplyRoutes = lazy(
  () => import("../modules/insuranceApply/connector/ModuleRoutes")
);
const ServicesRoutes = lazy(
  () => import("../modules/services/connector/ModuleRoutes")
);
const LoansDetailRoutes = lazy(
  () => import("../modules/loansDetail/connector/ModuleRoutes")
);
const CardsDetailRoutes = lazy(
  () => import("../modules/cardsDetail/connector/ModuleRoutes")
);
const DeepLinkRoutes = lazy(
  () => import("../modules/deeplink/connector/ModuleRoutes")
);
const CampaignRoutes = lazy(
  () => import("../modules/campaign/connector/ModuleRoutes")
);
const Page404 = lazy(() => import("../modules/error/presentation/Page404"));
const Page400 = lazy(() => import("../modules/error/presentation/Page400"));
const Page500 = lazy(() => import("../modules/error/presentation/Page500"));

export default function RoutesMain() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<HomeRoutes />} />
          <Route path="/finans/*" element={<FinanceRoutes />} />
          <Route path="/finans/sorular/:id" element={<FinanceRoutes />} />
          <Route path="/finans/krediler/*" element={<LoansRoutes />} />
          <Route
            path="/finans/krediler/detay*"
            element={<LoansDetailRoutes />}
          />
          <Route path="/finans/kartlar" element={<CardsRoutes />} />
          <Route path="/finans/kartlar/detay" element={<CardsDetailRoutes />} />
          <Route path="/finans/sigortalar*" element={<InsurancesRoutes />} />
          <Route
            path="/finans/sigortalar/basvuru*"
            element={<InsuranceApplyRoutes />}
          />
          <Route path="/hizmetler/*" element={<ServicesRoutes />} />
          <Route path="/iletisim/*" element={<ContactRoutes />} />
          <Route path="/hakkimizda/*" element={<AboutUsRoutes />} />
          <Route path="/400" element={<Page400 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="/kampanyalar/*" element={<CampaignRoutes />} />
          <Route path="/app/*" element={<DeepLinkRoutes />} />
          <Route path="/store" element={<HomeRedirectStore />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
