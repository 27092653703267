import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src="/assets/images/amblem.png"
        alt="Logo"
        className="loading-logo"
      />
      <div className="loading-text">Yükleniyor...</div>
    </div>
  );
};

export default Loading;
