import React from "react";

import "./assets/css/App.css";
import RoutesMain from "./routes/Routes";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "./fireBaseConfig";

function App() {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  logEvent(analytics, "view_page", {});
  return <RoutesMain />;
}

export default App;
