import { Container, Flex, Grid, rem, Skeleton, Text } from "@mantine/core";
import { UseMediaQueryHooks } from "../../../../hooks/UseMediaQueryHooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";

export default function HomeRedirectStore() {
  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      window.location.href =
        "https://apps.apple.com/tr/app/i-mecemobil/id1472318896";
    } else {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.softtech.imece&hl=tr&pli=1";
    }
  }, []);

  return null;
}
